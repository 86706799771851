<template lang="pug">
.laws
  cleanable-title(@reset="$emit('reset', 'lotLawIds')") ЗАКОНЫ
  .filter-card__checkboxes
    checkbox-group(v-model="modelValue.lotLawIds" :options="filters")
    ui-checkbox(
      as-group
      id="law_other_short"
      label="Прочие"
      v-model="isOtherSet"
    )
</template>

<script lang="ts">
import { computed, defineComponent, } from "vue";
import { useVModel } from "@vueuse/core";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

export default defineComponent({
  name: "LawsSimpleFilter",
  components: {
    UiCheckbox,
    CheckboxGroup,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const searchForm = useVModel(props, 'modelValue', context.emit);

    const filters = [
      { id: 1, title: 'Государственные закупки (44-ФЗ)', inputId: 'law_44_short' },
      { id: 2, title: 'Коммерческие закупки (223-ФЗ)', inputId: 'law_223_short' },
    ];

    const othersIds = [4, 5, 7, 8, 3]

    const isOtherSet = computed({
      get: () => othersIds.every(e => searchForm.value?.lotLawIds?.includes(e)),
      set: (value) => {
        if (value) searchForm.value.lotLawIds = [...new Set([...searchForm.value.lotLawIds || [], ...othersIds])]
        else searchForm.value.lotLawIds = searchForm.value.lotLawIds.filter(e => !othersIds.includes(e))
      },
    })

    return {
      filters,
      isOtherSet,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.laws {
  display: flex;
  flex-flow: column;

  .filter-card__checkboxes {
    display: flex;
    flex-flow: row;
    gap: 12px;

    :deep(.checkbox-group) {
      display: contents;
    }
  }
}
</style>
